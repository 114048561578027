import React, { useEffect, useState } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import * as actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import HrDashboardCard from '../../components/Card/HrDashboardCard';
import dayjs from 'dayjs';
import FilterTimestampDashboardCard from 'components/Card/FilterTimestampDashboardCard';

export default function HrDashboard({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const timestamp = useSelector((state) => state.timestamp);
  const project = useSelector((state) => state.project);
  const roletype = useSelector((state) => state.roletype);

  const [dateStart, setDateStart] = useState(new Date());
  const [roleSelect, setRoleSelect] = useState();
  const [projectSelect, setProjectSelect] = useState();

  //   const timestampGroup = _.groupBy(timestamp?.rows, (t) => t.project_in?._id);
  //   console.log(timestampGroup);

  useEffect(() => {
    dispatch(actions.projectAll({}));
    dispatch(actions.roletypeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.timestampAll({
        dash: true,
        dateStart: dayjs(dateStart).startOf('day').toISOString(),
        dateEnd: dayjs(dateStart).endOf('day').toISOString(),
      }),
    );
    return () => {};
  }, [dateStart]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderCard = () => <HrDashboardCard timestamp={timestamp} />;
  const renderCardQuery = () => (
    <div>
      <FilterTimestampDashboardCard
        dateStart={dateStart}
        setDateStart={setDateStart}
        project={project}
        setProjectSelect={setProjectSelect}
        projectSelect={projectSelect}
        roletype={roletype}
        setRoleSelect={setRoleSelect}
      />
    </div>
  );
  return (
    <div>
      {renderTitle()}
      {renderCardQuery()}
      {renderCard()}
    </div>
  );
}
