import OtCheckRe from '../../components/Table/OtCheckRe';
import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from 'components/Loading';
import dayjs from 'dayjs';
import FilterOtCard from 'components/Card/FilterOtCard';

export default function OtRequest({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const timestamp = useSelector((state) => state.timestamp);
  const me = useSelector((state) => state.me);
  const project = useSelector((state) => state.project);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [dateStart, setDateStart] = useState(dayjs());
  const [projectSelect, setProjectSelect] = useState();

  useEffect(() => {
    // dispatch(actions.timestampAll({ size, page, ot: true }));
    dispatch(
      actions.timestampAll({
        size,
        page,
        dateStart: dayjs(dateStart).startOf('day').toISOString(),
        dateEnd: dayjs(dateStart).endOf('day').toISOString(),
        project_id: projectSelect?._id,
      }),
    );
    dispatch(actions.getInformation());
    dispatch(actions.meGet());

    return () => {};
  }, [page, size, dateStart, projectSelect]);

  useEffect(() => {
    dispatch(actions.projectAll({}));

    return () => {};
  }, []);

  const renderApprove = async (each) => {
    const cofirm = window.confirm('confirm');
    if (cofirm) {
      const datasubmit = {
        ...each,
        ot_status: {
          approve: true,
          approver: me?.userData?.id,
          ...each?.ot_status,
          status: 'APPROVE',
        },
      };
      await dispatch(actions.timestampOtRequire(each?.id, { datasubmit }));
      dispatch(
        actions.timestampAll({
          size,
          page,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateStart).endOf('day').toISOString(),
          project_id: projectSelect?._id,
        }),
      );
    }
  };

  const renderReject = async (each) => {
    const cofirm = window.confirm('confirm');
    if (cofirm) {
      const datasubmit = {
        ...each,
        ot_status: {
          approve: true,
          approver: me?.userData?.id,
          ...each?.ot_status,
          status: 'REJECT',
        },
      };
      await dispatch(actions.timestampOtRequire(each?.id, { datasubmit }));
      dispatch(
        actions.timestampAll({
          size,
          page,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateStart).endOf('day').toISOString(),
          project_id: projectSelect?._id,
        }),
      );
    }
  };

  const renderTaableCheck = () => (
    <div>
      <div className="font-bold text-xl text-theme-600 p-2"> Check in </div>
      <OtCheckRe
        timestamp={timestamp}
        renderApprove={renderApprove}
        renderReject={renderReject}
      />
    </div>
  );

  const renderFillter = () => (
    <FilterOtCard
      setDateStart={setDateStart}
      projectSelect={projectSelect}
      setProjectSelect={setProjectSelect}
      project={project}
      dateStart={dateStart}
    />
  );

  const renderTableClaim = () => (
    <div className="py-2">
      <div className="font-bold text-xl text-theme-600 p-2"> Claim </div>
      <Card>
        <div className="p-2">waiting</div>
      </Card>
    </div>
  );
  if (!timestamp?.isLoading && timestamp.isCompleted)
    return (
      <div>
        {renderTitle()}
        <div>{renderFillter()}</div>
        <div>{renderTaableCheck()}</div>
        {/* <div>{renderTableClaim()}</div> */}
      </div>
    );
  else {
    return <Loading isLoading />;
  }
}
