import React, { useEffect, useState } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import * as actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterTimestampReportCard from '../../components/Card/FilterTimestampReportCard';
import { Card } from '@mui/material';
import _ from 'lodash';
import HrcheckinListReportPersonal from 'components/Table/HrcheckinListReportPersonal';
import { timestampReportPersonal, timeStampMontlyReport } from 'components/PDF';
import Monthlyreport from 'components/Card/Monthlyreport';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(weekday);
dayjs.extend(localeData);

export default function HrReport({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employee);
  const timestamp = useSelector((state) => state.timestamp);
  const roletype = useSelector((state) => state.roletype);
  const project = useSelector((state) => state.project);

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(9999);
  const [emSelect, setEmSelect] = useState();
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [month, setMonth] = useState(dayjs().month());
  const [year, setYear] = useState(dayjs().year());
  const [roleSelect, setRoleSelect] = useState();
  const [projectSelect, setProjectSelect] = useState();

  const daysInMonth = dayjs(`${year}-${month + 1}`).daysInMonth();
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  useEffect(() => {
    dispatch(
      actions.timestampAll({
        cal: true,
        size,
        page,
        emSelect,
        dateStart: dayjs().month(month).startOf('month').toISOString(),
        dateEnd: dayjs().month(month).endOf('month').toISOString(),
        roleSelect,
        project_id: projectSelect?._id,
      }),
    );

    return () => {};
  }, [emSelect, dateStart, dateEnd, month, roleSelect, projectSelect]);

  useEffect(() => {
    dispatch(actions.employeeAll({}));
    dispatch(actions.roletypeAll({}));
    dispatch(actions.projectAll({}));

    return () => {};
  }, []);

  const handlePrint = () => {
    // timestampReportPersonal(timestamp?.rows);
    timeStampMontlyReport(timestamp, days, month, year, dateStart);
  };

  const renderCardQuery = () => (
    <div>
      <FilterTimestampReportCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        timestamp={timestamp}
        handlePrint={handlePrint}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        roletype={roletype}
        roleSelect={roleSelect}
        setRoleSelect={setRoleSelect}
        project={project}
        setProjectSelect={setProjectSelect}
      />
    </div>
  );

  // const renderTable = () => (
  //   <div>
  //     {_.map(timestamp?.rows, (e) => (
  //       <div className="py-2">
  //         <Card>
  //           <div>
  //             <div className="flex justify-between p-2 text-theme-500 ">
  //               <div className="flex">
  //                 <h1 className="text-lg">Name: </h1>
  //                 <h1 className="text-lg">{e?.employeeDetails?.firstname}</h1>
  //                 <h1 className=" text-lg ml-2">
  //                   {e?.employeeDetails?.lastname}
  //                 </h1>
  //               </div>
  //             </div>
  //             <div>
  //               <HrcheckinListReportPersonal timestamp={e} />
  //             </div>
  //           </div>
  //         </Card>
  //       </div>
  //     ))}
  //   </div>
  // );

  const renderReportMonthly = () => (
    <Card>
      <div className="p-1">
        <Monthlyreport
          timestamp={timestamp}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
          daysInMonth={daysInMonth}
          days={days}
          dateStart={dateStart}
        />
      </div>
    </Card>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderCardQuery()}
      {renderReportMonthly()}
      {/* {renderTable()} */}
    </div>
  );
}
