import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import FilterTimestampCard from '../../components/Card/FilterTimestampCard';
import dayjs from 'dayjs';
import {
  timestampDailyReport,
  timestampDailySalaryReport,
} from '../../components/PDF';
import HrcheckinListReportDaily from 'components/Table/HrcheckinListReportDaily';
import FilterTimestampDailyCard from '../../components/Card/FilterTimestampDailyCard';
import { ButtonGroup } from '../../components/Button';
import HrDashboardCard from '../../components/Card/HrDashboardCard';

const options = [
  'Print Without Salary',
  'Print With Salary',
  'Print Checkin Daily',
];

export default function HrReportDaily({ title, subtitle }) {
  const dispatch = useDispatch();
  const timestamp = useSelector((state) => state.timestamp);
  const employees = useSelector((state) => state.employee);
  const project = useSelector((state) => state.project);
  const roletype = useSelector((state) => state.roletype);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(30);
  const [emSelect, setEmSelect] = useState();
  const [projectSelect, setProjectSelect] = useState();
  const [dateStart, setDateStart] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const [roleSelect, setRoleSelect] = useState();

  useEffect(() => {
    if (selectedIndex === 2) {
      dispatch(
        actions.timestampAll({
          dash: true,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateStart).endOf('day').toISOString(),
        }),
      );
    } else {
      dispatch(
        actions.timestampAll({
          size,
          page,
          emSelect,
          dateStart: dayjs(dateStart).startOf('day').toISOString(),
          dateEnd: dayjs(dateStart).endOf('day').toISOString(),
          sort: true,
          project_id: projectSelect?._id,
          roleSelect,
        }),
      );
    }

    return () => {};
  }, [
    page,
    size,
    emSelect,
    dateStart,
    dateEnd,
    projectSelect,
    roleSelect,
    selectedIndex,
  ]);

  useEffect(() => {
    dispatch(actions.projectAll({}));
    dispatch(actions.employeeAll({}));
    dispatch(actions.getInformation());
    dispatch(actions.roletypeAll({}));
    return () => {};
  }, []);

  const handlePrint = () => {
    const data = { ...timestamp, project: projectSelect, date: dateStart };
    timestampDailyReport(data);
  };

  const handlePrintWithSalary = () => {
    const data = { ...timestamp, project: projectSelect, date: dateStart };
    timestampDailySalaryReport(data);
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    if (selectedIndex === 0) {
      handlePrint();
    }
    if (selectedIndex === 1) {
      handlePrintWithSalary();
    }
  };

  const renderCardQuery = () => (
    <div>
      <FilterTimestampDailyCard
        employees={employees}
        emSelect={emSelect}
        setEmSelect={setEmSelect}
        dateStart={dateStart}
        dateEnd={dateEnd}
        setDateStart={setDateStart}
        setDateEnd={setDateEnd}
        timestamp={timestamp}
        handlePrint={handlePrint}
        project={project}
        setProjectSelect={setProjectSelect}
        projectSelect={projectSelect}
        renderButtonGrotup={renderButtonGrotup}
        roletype={roletype}
        setRoleSelect={setRoleSelect}
      />
    </div>
  );

  const renderTable = () => {
    if (selectedIndex === 2) {
      return (
        <div>
          <HrDashboardCard timestamp={timestamp} />
        </div>
      );
    } else
      return (
        <HrcheckinListReportDaily
          timestamp={timestamp}
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
        />
      );
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButtonGrotup = () => (
    <ButtonGroup
      options={options}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      handleClick={handleClick}
    />
  );

  if (!timestamp?.isLoading && timestamp?.isCompleted)
    return (
      <div>
        {renderTitle()}
        <div className="py-1">{renderCardQuery()}</div>

        <div>{renderTable()}</div>
      </div>
    );
  else return <isLoading isLoading />;
}
