import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BookOpen as BookOpenIcon,
  Code as CodeIcon,
  Activity as ActivityIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Droplet as DropletIcon,
  DollarSign,
  Calendar,
  Trello,
  ToggleRight,
  Clock,
  Users,
} from 'react-feather';
import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
import accessRight from '../../utils/functions/accessRight';
import * as actions from '../../redux/actions';

const items = [
  // {
  //   href: '/monitor/dashboard',
  //   icon: ActivityIcon,
  //   title: 'แดชบอร์ด',
  //   name: 'MONITOR',
  //   sub: [],
  // },
  // {
  //   href: '/profile/calendar',
  //   icon: Calendar,
  //   title: 'ตารางาน',
  //   name: 'PROFILE',
  //   sub: [],
  // },
  {
    href: '/profile/timestamp',
    icon: Clock,
    title: 'ลงเวลา',
    name: 'PROFILE',
    sub: [],
  },
  {
    href: '/profile/disbursement',
    icon: DollarSign,
    title: 'เบิกจ่าย',
    name: 'PROFILE',
    sub: [],
  },

  // {
  //   href: '/project/project',
  //   icon: ActivityIcon,
  //   title: 'project',
  //   name: 'PROJECT',
  //   sub: [],
  // },
  {
    href: '/project/project',
    icon: ActivityIcon,
    title: 'Project ',
    name: 'PROJECT',
    sub: [
      // {
      // //   href: '/project/dashboard',
      // //   title: 'Dashboard',
      // // },
      // {
      //   href: '/project/project',
      //   title: 'Project',
      // },
    ],
  },
  {
    href: '/humen/timestamp',
    icon: Users,
    title: 'Humen',
    name: 'HUMEN',
    sub: [
      // {
      //   href: '/humen/timestamp-dashboard',
      //   title: 'Dashboard',
      // },
      {
        href: '/humen/timestamp',
        title: 'Timestamp List',
      },
      {
        href: '/humen/ot-request',
        title: 'OT Request',
      },
      {
        href: '/humen/reportDaily',
        title: 'Report Daily',
      },
      {
        href: '/humen/report',
        title: 'Report Monthly',
      },

      {
        href: '/humen/employee',
        title: 'Employee',
      },
    ],
  },
  {
    href: '/finance/expenses',
    icon: FileTextIcon,
    title: 'Finance',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/finance/expenses',
        title: 'Expenses',
      },
      {
        href: '/finance/billing',
        title: 'Billing',
      },
    ],
  },
  {
    href: '/customer',
    icon: UserIcon,
    title: 'Customer',
    name: 'CUSTOMER',
    sub: [
      {
        href: '/customer/customers',
        title: 'Customer',
        level: 0,
      },
      {
        href: '/customer/customer-types',
        title: 'Customer type',
        level: 1,
      },
    ],
  },
  {
    href: '/director/project',
    icon: Trello,
    title: 'Director',
    name: 'DIRECTOR',
    sub: [
      {
        href: '/director/project',
        title: 'Project overview',
        level: 0,
      },
      {
        href: '/director/hr',
        title: 'HR overview',
        level: 0,
      },
      // {
      //   href: '/customer/customer-types',
      //   title: 'ประเภทลูกค้า',
      //   level: 1,
      // },
    ],
  },

  {
    href: '/profile/profile',
    icon: UserIcon,
    title: 'Profile',
    name: 'PROFILE',
    sub: [],
  },
  {
    href: '/management/employee',
    icon: ToggleRight,
    title: 'Setting',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/management/employee',
        title: 'Employee',
      },
      {
        href: '/management/holiday',
        title: 'Holiday list',
      },
      {
        href: '/management/department',
        title: 'Department',
      },
      {
        href: '/management/role-type',
        title: 'Role',
      },
      {
        href: '/management/user',
        title: 'User',
      },
      {
        href: '/management/business',
        title: 'Business setting',
      },
    ],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));

  const user = {
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'primary.main',
      }}
    >
      <Box className="p-6">
        <div className="p-2 rounded-xl flex flex-wrap justify-center  bg-theme-500">
          <div className="lg:w-1/3 flex justify-center ">
            <div className="bg-gray-300 rounded-full p-1">
              <UserIcon size={40} color={'gray'} />
            </div>
          </div>
          <div className="w-full lg:w-2/3 text-center mt-3">
            <Typography color="textPrimary" variant="body2">
              {user?.name}
            </Typography>
            <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
            </Typography>
          </div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2, backgroundColor: 'primary.main' }}>
        <List>
          {items.map((item) => {
            if (accessRight(me, item?.name)) {
              return (
                <div key={item.title}>
                  <NavItem
                    href={item.href}
                    title={item.title}
                    icon={item.icon}
                    sub={item.sub}
                    me={me}
                    moduleName={item.name}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          backgroundColor: 'primary.main',
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          color="black1"
          onClick={() => {
            onSignOut();
          }}
        >
          log out
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 235,
              // top: 64,
              // height: 'calc(100% - 64px)',
              // zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  me: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
